import { ElLoading } from 'element-plus'

let needLoadingRequestCount = 0
let loadingInstance

export function tryStartLoading() {
    if (needLoadingRequestCount === 0) {
        loadingInstance = ElLoading.service(
            {
                background: 'rgba(0, 0, 0, 0.1)'
            })
    }
    needLoadingRequestCount++
}

export function tryCloseLoading() {
    needLoadingRequestCount--
    if (needLoadingRequestCount === 0) {
        loadingInstance.close()
    }
}