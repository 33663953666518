import axios from "axios";
import { ElMessage } from "element-plus";
import { tryStartLoading, tryCloseLoading } from "./loading";

export const baseURL = "https://memo.keymemoapp.com/api/dev/1.0.0";
axios.defaults.withCredentials = true;
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.device = 'web'
const service = axios.create({
  method: "post",
  baseURL: baseURL,
  timeout: 25000,
});
service.interceptors.request.use(
  (config) => {
    if (!config.data) {
      tryStartLoading();
    }
    return config;
  },
  (error) => {
    tryCloseLoading();
    ElMessage({
      type: "error",
      message: "请求错误",
      duration: 2000,
    });
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    // 加载弹框组件
    tryCloseLoading();
    const res = response.data;
    // 判断响应状态
    if (res.code === "0") {
      return Promise.resolve(res.data);
    } else {
      ElMessage({
        type: "error",
        message: res.msg,
        duration: 4000,
      });
      return Promise.reject(res);
    }
  },
  (error) => {
    tryCloseLoading();
    ElMessage({
      type: "error",
      message: error,
      duration: 4000,
    });
    return Promise.reject(error);
  }
);

export default service;
