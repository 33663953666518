<template>
  <div class="div_passworld">
    <h3 class="h3_title">重设密码</h3>
    <p class="p_explain">密码需要8-20位，至少含字母、数字、字符的任意两种</p>
    <div class="div_input_body">
      <InputPhone ref="phone" v-model="newObj.mobile"></InputPhone>
      <InputCode
        ref="code"
        v-model="newObj.sms_secret"
        :getCode="getCode"
      ></InputCode>
      <InputPassworld
        v-model="newObj.password"
        ref="passworld"
      ></InputPassworld>
      <InputPassworld
        placeholder="确认你的密码"
        v-model="newObj.confirm_password"
        ref="confirm_password"
      ></InputPassworld>
    </div>
    <button
      type="button"
      class="butsubmit"
      :class="canSubmit() ? 'btnGroupOne' : 'btnGroupNone'"
      @click="clickSubmit"
    >
      确定
    </button>
  </div>
</template>

<script setup>
import { ref } from "vue";
import InputPhone from "@/components/input/inputPhone.vue";
import InputCode from "@/components/input/inputCode.vue";
import InputPassworld from "@/components/input/inputPassworld.vue";
import { ElMessage } from "element-plus";
import md5 from "js-md5";
import // sendMessage,/
//  userForget
"@/api/login";
import { useRouter } from "vue-router";
import { sendValidateCode, forgotPassword } from "@/api/api.js";

const router = useRouter();

let newObj = ref({
  mobile: "",
  sms_key: "",
  sms_secret: "", //验证码
  password: "", //密码
  confirm_password: "", //密码
});
// --ref---
let phone = ref(null);
let code = ref(null);
let passworld = ref(null);
let confirm_password = ref(null);

// 点击获取验证码
function getCode(callback) {
  if (newObj.value.mobile.length == "") {
    phone.value.worring = true;
    ElMessage({
      message: "请输入手机号",
      type: "error",
    });
    return;
  } else if (newObj.value.mobile.length != 11) {
    phone.value.worring = true;
    ElMessage({
      message: "请输入正确的手机号",
      type: "error",
    });
    return;
  }
  // 发送验证码
  // sendMessage({ mobile: newObj.value.mobile }).then((data) => {
  //   newObj.value.sms_key = data.sms_key;
  //   callback(true);
  //   ElMessage({
  //     message: "验证码已发送",
  //     type: "message",
  //   });
  // });
  sendValidateCode(newObj.value.mobile, 2).then(() => {
    callback(true);
    ElMessage({
      message: "验证码已发送",
      type: "message",
    });
  });
}
// 验证
function verification() {
  let isOk = true;
  let worrIng = "";
  let reg =
    /^(?!^\d+$)(?!^[a-z]+$)(?!^[A-Z]+$)(?!^[^a-z0-9]+$)(?!^[^A-Z0-9]+$)(?!^.*[\u4E00-\u9FA5].*$)^\S*$/;
  // 验证码登录
  if (newObj.value.mobile == "") {
    isOk = false;
    phone.value.worring = true;
    worrIng = "请输入手机号";
  } else if (newObj.value.mobile.length != 11) {
    isOk = false;
    phone.value.worring = true;
    worrIng = "请输入正确的手机号";
  } else if (newObj.value.sms_secret == "") {
    isOk = false;
    code.value.worring = true;
    worrIng = "请输入验证码";
  } else if (newObj.value.password == "") {
    isOk = false;
    passworld.value.worring = true;
    worrIng = "请输入密码";
  } else if (reg.test(newObj.value.password) == false) {
    isOk = false;
    passworld.value.worring = true;
    worrIng = "密码格式不正确";
  } else if (newObj.value.password != newObj.value.confirm_password) {
    isOk = false;
    passworld.value.worring = true;
    confirm_password.value.worring = true;
    worrIng = "俩次密码输入不一致";
  }

  if (!isOk)
    ElMessage({
      message: worrIng,
      type: "error",
    });
  return isOk;
}
function canSubmit() {
  let isok = true;
  for (const key in newObj.value) {
    if (newObj.value[key] == "" && key != "sms_key") {
      isok = false;
      break;
    }
  }
  return isok;
}
// 提交
function clickSubmit() {
  if (!verification()) return;
  // userForget(newObj.value).then(() => {
  //   ElMessage({
  //     icon: "SuccessFilled",
  //     type: "success",
  //     message: "修改成功",
  //   });
  //   router.replace({ name: "login" });
  // });
  forgotPassword(
    newObj.value.mobile,
    newObj.value.sms_secret,
    2,
    md5(newObj.value.password)
  ).then((res) => {
    if (res.code == 200) {
      ElMessage({
        icon: "SuccessFilled",
        type: "success",
        message: "修改成功",
      });
      router.replace({ name: "login" });
    } else if (res.code == 324) {
      ElMessage({
        icon: "SuccessFilled",
        type: "worrming",
        message: "请输入正确的密码",
      });
    } else {
      ElMessage({
        icon: "SuccessFilled",
        type: "worrming",
        message: "重置密码失败请重试！",
      });
    }
  });
}
</script>

<style lang='scss' scoped>
.div_passworld {
  padding: 40px;
  text-align: center;
  .p_explain {
    margin: 8px 0px 32px;
  }
  .div_input_body {
    text-align: left;
    > div {
      margin-bottom: 24px;
    }
    .flex_between {
      margin-top: 16px;
      line-height: 1;
      font-size: 14px;
      color: #1f2328;
    }
  }
  .butsubmit {
    width: 100%;
    height: 60px;
    margin: 24px 0px 20px;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 500;
  }
}
</style>